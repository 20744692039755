import React, { useState, useEffect } from 'react';
import './App.css';
import Footer from './components/footer/Footer';
import AboutUs from './pages/aboutPage/Aboutpage';
import ProductListing from './pages/productPage/Productpage';
import axios from 'axios';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { defaultSettings, Settings, baseApiUrl, baseUrl } from "./global";
import Cartpage from './pages/cartPage/Cartpage';
import Checkoutpage from './pages/checkoutPage/Checkoutpage';
import { useSelector } from 'react-redux';
import ChatBot from 'react-chatbotify';
import {HubConnectionBuilder, HubConnectionState} from "@microsoft/signalr";
import NotificationsIcon from '@mui/icons-material/Notifications';

function App() {

  const [activeMenu, setActiveMenu] = useState(0);
  const [page, setPage] = useState(true);
  const [hide, setHide] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(undefined);
  const [search, setSearch] = useState('');
  const [makeCall, setMakecall] = useState(false);
  const [settings, setSettings] = useState(defaultSettings);
  const [notifications, setNotifications] = useState([]);
  const itemsCount = useSelector(state => state.orderSummary.totalItems);
  const [showNotifications, setShowNotifications] = useState(false);
  const menuOptions = [{ id: 0, page: <ProductListing input={search} category={category} call={makeCall} pageSize={settings.pageSize} setCall = {setMakecall}/>, pageName: 'Home' },
  { id: 1, page: <AboutUs />, pageName: 'About Us' }, { id: 2, page: null }, { id: 3, page: <Cartpage checkout={() => { setPage(false); setActiveMenu(4); }}/> , pageName: 'Cart'},
  { id: 4, page: <Checkoutpage /> , pageName: 'Checkout'}];


  //for chatbot only
  const [showBot, setShowBot] =  useState(false);
const [name, setName] = useState('');
const [contact, setContact] = useState('');
const helpOptions = ["How to make payment", "Location", "How to search item", "Delivery time", "Discount", 'Talk to Support'];
const [connection, setConnection] = useState(new HubConnectionBuilder()
.withUrl(`${baseUrl}chatHub`,{
  withCredentials: false,
}).withAutomaticReconnect()
.build());

var chatbotParams = null; // var to store chatbot parma object to inject mesage from the admin
useEffect(() => {

  if(connection.state === 'Disconnected')
  connection.start()
  .then(() => {
    connection.invoke("AddToGroup");
});

},[]);


useEffect(() => {
  if(connection.state === "Connected"){
    connection.invoke("Connection", {
      userId : name+"_"+contact,
      username : name,
      connectionId : '',
      active: true 
    });
  }
  
},[contact]);

useEffect(() => {
    connection.on("msgFromAdmin", (m) => {
        
      chatbotParams.injectMessage(m.message);
      //connection.off("msgFromAdmin");
  }); 

  connection.on("newNotification", (newNotification) => {
    setNotifications([...notifications, newNotification]);
  });
  
}); 


const sendMessage = (msg,params) => {
  chatbotParams = {...params};
  if(connection.state === "Connected"){
    connection.invoke("SendMessage", {senderId: name.toString()+'_'+contact,
  receiverId: 'admin__', message : msg, userId: name.toString()+'_'+contact,
datetime: Date.now().toString()});
  }
}

const flow = {
  start: {
      message: "Hi, how can i help you?",
      transition: {duration: 1000},
      path: "show_options"
  },
  show_options: {
      message: "Select question to get sol",
      options: helpOptions,
      path: "process_options"
  },
  prompt_again: {
      message: "Do you need any other help?",
      options: helpOptions,
      path: "process_options"
  },
  unknown_input: {
      message: "Sorry, I do not understand your message 😢! If you require further assistance, you may click on " +
          "the Github option and open an issue there or visit our discord.",
      options: helpOptions,
      path: "process_options"
  },
  process_options: {
      transition: {duration: 0},
      path: (params) => {
          switch (params.userInput.toString().trim().toLowerCase()) {
          
            case "how to make payment":
              return 'how_to_make_payment';

            case "location":
              return 'location';

            case "how to search item":
              return 'how_to_search_item';

            case "delivery time":
              return 'delivery_time';
            case "discount":
              return "discount";
                
            case "talk to support":
              return 'talk_to_support';

            case "no":
              return 'no';

            case "no, ok bye":
              return 'no_ok_bye';

            case "bye":
              return 'bye';

            default:
                return "unknown_input";
          }
          // params.injectMessage("Sit tight! I'll send you right there!");
          // setTimeout(() => {
          //     window.open(link);
          // }, 1000)
      },
  },
  repeat: {
      transition: {duration: 0},
      path: "prompt_again"
  },

  how_to_make_payment: {
    message: "We accept payment via card, easypaisa and jazzcash. Our account no is 03460845349",
    transition: {duration: 4000},
    path: "repeat"
  },

  location: {
    message: "We are located at lat: 34.6767 lon: 35.8787 \n",
    transition: {duration: 4000},
    path: "repeat" 
  },
  
  how_to_search_item:{
    message: "You can search via name of the product and you can also filter the data.",
    transition: {duration: 4000},
    path: "repeat"
  },

  delivery_time: {
    message: "We have delivery time of 2 hrs for groceries and for other items we make delivery in 2 days",
    transition: {duration: 4000},
    path: "repeat"
  },

  discount:{
    message: "Discounts are of two types, one is that is displayed on each item and then there is also some discounts on whole sale.",
    transition: {duration: 4000},
    path: "repeat"
  },

  talk_to_support:{
    message: "please provide your name",
    path: (params) => {
        setName(params.userInput.toString().toLowerCase());
        return "contact_input";
    }
  },

  contact_input: {
    message: 'Please enter your contact with country code excluding (+) like 92123456789',
    path: (params) => {
        setContact(params.userInput.toString().toLowerCase());
        params.injectMessage("Connecting to customer support .....");
        return 'talk_to_admin';
    }
  },

  talk_to_admin: {
    message: (params) => {
      sendMessage(params.userInput.toString(), params);
    },
    path:  'talk_to_admin'
    
  },

  reply_:{
    transition: {duration: 0},
    path: 'talk_to_admin'
  },


  end: {
    message: "Have a Nice Day!",
    chatDisabled: true
},

  no:{
    transition: {duration: 0},
    path: "end"
  },

  no_ok_bye:{
    transition: {duration: 0},
    path: "end"
  },

  bye:{
    transition: {duration: 0},
    path: "end"
  },

  
};

  //getting categories from backend

  useEffect(() => {
    axios.get(`${baseApiUrl}Customer/getCategories`).then((res) => {
      setCategories(res.data);
      console.log(res.data);
    }).catch(err => {
      console.log('Error in making request');
    });

    Settings().then(res => {
      setSettings(res);
    }).catch(err => {
      console.log(err);
    })

  }, []);

  useEffect(() => {

    axios.get(`${baseApiUrl}Notification/getNotifications`).then((res) => {
      debugger;
      setNotifications(res.data);
    });
  }, []);

  const handleMenuClick = (id) => {
    setActiveMenu(id);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    var width = window.innerWidth;
    if (width < 769) {
      document.getElementsByClassName('links-div')[0].style.display = 'none';
      document.getElementsByClassName('toggle-btn')[0].style.display = 'block';
    }
    else {
      document.getElementsByClassName('links-div')[0].style.display = 'block';
      document.getElementsByClassName('toggle-btn')[0].style.display = 'none';
      document.getElementsByClassName('links-div')[0].style.display = 'flex';
      document.getElementsByClassName('links-div')[0].style.flexDirection = 'row';
    }
  }

  const handleToggle = () => {
    var width = window.innerWidth;
    if (hide) {
      document.getElementsByClassName('links-div')[0].style.display = 'block';
      document.getElementsByClassName('links-div')[0].style.display = 'flex';
      document.getElementsByClassName('links-div')[0].style.flexDirection = 'column';
      setHide(!hide);
    }
    else {
      document.getElementsByClassName('links-div')[0].style.display = 'none';
      setHide(!hide);
    }
  }

  const handleSearch = () => {
    // changing the search state to make api call in product page

    setMakecall(true);

  }

  return (
    <div className="app">

<div className='message-us' onClick={() => {setShowBot(!showBot)}}>
          <p>{showBot ? 'X' : 'O'}</p>
</div>
{
        showBot ? 
      <div className='chatbot'>
      <ChatBot options={{theme: {embedded: true, showFooter: false}, chatHistory: {storageKey: "example_faq_bot"}, header:{
        title:'Chatbot'
      }}} flow={flow}/> </div> : <></>}

      <div className='top-section'>
        <header className='top-header'>
          <p className=''>{settings.companyName}</p>
          <div className='header-right'>
            <p className='app-name'>{settings.companyContact}</p>
            <IconButton aria-label="notification" className='notify-btn' onClick={()=>{setShowNotifications(!showNotifications);}}>
              <Badge badgeContent={notifications.length} className='notify-badge'>
                <NotificationsIcon className='notify-icon'/>
              </Badge>
            </IconButton>    
            <IconButton aria-label="cart" className='cart-btn' onClick={()=>{setPage(false); setActiveMenu(3);}}>
              <Badge badgeContent={itemsCount} className='cart-badge'>
                <ShoppingCartIcon className='cart-icon'/>
              </Badge>
            </IconButton>
            {
              showNotifications ? 
              <div className='notify-modal'>
                  {
                    notifications.map((n) => (
                      <div className='notify-div'>
                        <p className='notify-desc'>{n.description}</p>
                      </div>
                    ))
                  }
              </div> : <></>
            }
            
            {/* <button className='logout-btn' onClick={() => { }}>Logout</button> */}
          </div>
        </header>
        <nav className="menu">
          <div className='menu-left'>
            <p className='app-name' onClick={() => { }}>
              {menuOptions[activeMenu].pageName}
            </p>
          </div>
          <div className='menu-right'>
            <button className='toggle-btn link' onClick={handleToggle}>
              Menu
            </button>
            <div className='links-div'>
              <button className='link' onClick={() => {
                setPage(true); setActiveMenu(0);
              }}>
                Home
              </button>
              <button className='link' onClick={() => { setPage(false); setActiveMenu(1); }}>
                About us
              </button>
              <button className='link' onClick={() => { }}>
                Contact us
              </button>
            </div>
          </div>
        </nav>

        <div className='divider-wrapper'><hr className='divider' /></div>

        {page ?
          <div className='hero-area'>
            <h1 className='hero-heading'> <span className='heading-link'>Buy</span>, <span className='heading-link'>Explore</span> or <span className='heading-link'>Find</span> What You need</h1>
            <div className='form'>
              <div className='form-input'>
                <label className='input-label'>What?</label>
                <input type="text" placeholder='What are you looking for?' onChange={(e) => { setSearch(e.target.value); console.log(e.target.value); setMakecall(false); }} />
              </div>
              <div className='form-picker'>
                <label className='input-label'>Category?</label>
                {categories.length < 1 ?
                  <select onChange={(e) => { setCategory(e.target.value); }} className='picker' defaultValue=''>
                    <option value="" disabled selected>Loading...</option>
                  </select>
                  :
                  <select onChange={(e) => { setCategory(e.target.value); console.log(e.target.value); setMakecall(true); }} className='picker'>
                    <option value="" disabled selected>Select Category</option>
                    <option value={'All Categories'}>All Categories</option>
                    {categories.map((cat) =>
                      <option id={cat.id} value={cat.name}>{cat.name}</option>
                    )}
                  </select>
                }
              </div>
              <div className='form-btn'>
                <label className='input-label'>btnlabel</label>
                {
                  category === undefined && search === '' ?
                    <button disabled onClick={() => { console.log('rola') }}> Search</button>
                    :
                    <button onClick={handleSearch}> Search</button>}
              </div>


            </div>


          </div> : <></>
        }

      </div>

      {/* Selected Page to be loaded */}
      {menuOptions[activeMenu].page}
      {/* 
      
      ***************************88CAROUSAL******************
      <div style={{height:'50vh', width: '100%', marginBottom:'5px'}}>
      <Carousel />
      </div> */}

      {/* <div>
        <ImagesCarousal />
      </div> */}

      <Footer address={settings.companyAddress} contact={settings.companyContact} email={settings.companyEmail}
        fb={settings.fbUrl} insta={settings.instaUrl} wa={settings.whatsappUrl} />





    </div>

    
  );
}


export default App;


